<template>
  <div class="elv-reconciliation-matchSet-detail-rule-draggable">
    <div>
      <SvgIcon
        v-if="props.row.priority !== 1"
        width="20"
        height="20"
        name="draggable"
        class="elv-reconciliation-matchSet-detail-rule-draggable__icon"
      />
      <svgIcon v-else width="16" height="16" name="locked" class="is-disabled" />
    </div>
    <span>{{ props.row.priority }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  row: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-matchSet-detail-rule-draggable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 35px;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    cursor: move;
    fill: #dde1e6;

    &.is-disabled {
      cursor: not-allowed;
    }

    &:hover {
      fill: #4674eb;
    }
  }

  span {
    width: 50px;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #aaafb6;
  }
}
</style>
