<template>
  <div class="elv-match-set-filter-conditions">
    <el-select
      v-model="conditionData.type"
      popper-class="elv-match-set-filter-condition-input-popper"
      class="elv-match-set-filter-condition-type"
      :placeholder="t('common.selectCondition')"
      @change="onChangeConditionType"
    >
      <el-option
        v-for="(item, i) in conditionTypeOption"
        :key="i"
        :label="`${transformI18n(item.label)} ${
          item.value === 'AMOUNT_FC' ? `(${entityStore.entityDetail?.defaultCurrency})` : ''
        }`"
        :value="item.value"
        :disabled="disableConditionType(item.value)"
      />
    </el-select>

    <GroupMultipleChoiceSelect
      v-if="conditionData.type === 'TRANSACTION_TYPE'"
      ref="transactionSelectRef"
      v-model="conditionData.value"
      placeholder="Select transaction type"
      width="360px"
      split-string="_"
      model-type=""
      :max-collapse-tags="2"
      class="elv-match-set-filter-condition-long-input"
      :group-option="transactionType"
      @change="onChangeTransactionType"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'CURRENCY'"
      v-model="conditionData.value"
      placeholder="Input currency code"
      class="elv-match-set-filter-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      remote
      remote-show-suffix
      reserve-keyword
      :loading="currencyLoading"
      :remote-method="remoteCurrencyMethod"
      collapse-tags
      collapse-tags-tooltip
      :is-remote-icon="true"
      :max-collapse-tags="2"
      :options="currencyOptions"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <template v-else-if="conditionData.type === 'DATETIME'">
      <DateTimeCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        @onChangeType="onChangeType"
      />
    </template>

    <template v-else-if="['AMOUNT', 'AMOUNT_FC'].includes(conditionData.type)">
      <AmountCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        @onChangeType="onChangeType"
        @onChangeCondition="onChangeCondition"
      />
    </template>

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'ENTITY_ACCOUNT'"
      v-model="conditionData.value.entityAccountIds"
      placeholder="Select account"
      class="elv-match-set-filter-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="accountList"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'COUNTERPARTY'"
      v-model="conditionData.value.counterpartyIds"
      :placeholder="t('placeholder.enterNameForSearch')"
      class="elv-match-set-filter-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      remote
      remote-show-suffix
      reserve-keyword
      :loading="searchCounterpartyLoading"
      :remote-method="remoteCounterpartyMethod"
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="counterpartyList"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <AuxiliaryCondition
      v-else-if="conditionData.type === 'AUXILIARY'"
      v-model:value="conditionData.value"
      v-model:auxiliaryTypeId="conditionData.value.auxiliaryTypeId"
      :account-list="accountList"
      :counterparty-list="counterpartyList"
      :search-counterparty-loading="searchCounterpartyLoading"
      :auxiliary-types="props.chartOfAccount?.auxiliaryTypes ?? []"
      @onChangeType="onChangeType"
      @onChangeCondition="onChangeCondition"
      @onChangeAuxiliary="onChangeAuxiliary"
    />

    <template v-else-if="conditionData.type === 'MEMO'">
      <MemoCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        @onChangeType="onChangeType"
        @onChangeCondition="onChangeCondition"
      />
    </template>

    <div class="elv-match-set-filter-condition-operating">
      <SvgIcon
        name="sources-delete"
        width="18"
        height="18"
        :class="{ 'is-disabled': props.conditionList.length === 1 && props.index === 0 && !props.condition.type }"
        class="elv-match-set-filter-condition-operating__delete"
        @click="onDeleteCondition"
      />
      <SvgIcon
        name="source-add"
        width="18"
        height="18"
        class="elv-match-set-filter-condition-operating__add"
        @click="onAddCondition"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ConfigApi from '@/api/ConfigApi'
import AccountsApi from '@/api/AccountsApi'
import { transactionType } from '@/config/index'
import MemoCondition from './MemoCondition.vue'
import { $t, transformI18n } from '@/i18n/index'
import { CurrencyItemType } from '#/ReportsTypes'
import AmountCondition from './AmountCondition.vue'
import DateTimeCondition from './DateTimeCondition.vue'
import { useEntityStore } from '@/stores/modules/entity'
import AuxiliaryCondition from './AuxiliaryCondition.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import GroupMultipleChoiceSelect from '@/components/Base/GroupMultipleChoiceSelect.vue'
import { pick, isEmpty, isObject, isArray, uniqBy, includes, cloneDeep, difference, capitalize } from 'lodash-es'

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  condition: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  conditionList: {
    type: Array,
    required: true
  },
  chartOfAccount: {
    type: Object as () => Record<string, any>,
    default: () => {
      return {}
    }
  },
  entityAccountAuxiliaryTypeId: {
    type: String,
    default: ''
  },
  currencyList: {
    type: Array<CurrencyItemType>,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const counterpartyOptions: any = ref([])
const originCounterpartyOptions: any = ref([])

const emit = defineEmits(['onConditionChange', 'addCondition'])

const addressData = ref()
const currencyLoading = ref(false)
const searchCounterpartyLoading = ref(false)
const transactionSelectRef = ref()
const currenciesData = ref<CurrencyItemType[]>([])

const conditionData: any = ref({ operator: 'EQ', type: '', value: '' })

const conditionTypeOption = computed(() => {
  if (props.type === 'ledger') {
    const list = [
      { label: $t('common.currency'), value: 'CURRENCY' },
      { label: $t('report.Date'), value: 'DATETIME' }
      // { label: $t('common.amount'), value: 'AMOUNT' }
      // { label: $t('common.amount'), value: 'AMOUNT_FC' },
      // { label: $t('report.memo'), value: 'MEMO' }
    ]
    if (!isEmpty(props.chartOfAccount) && !!props.chartOfAccount?.auxiliaryTypeIds.length) {
      list.push({ label: $t('report.auxiliaryCode'), value: 'AUXILIARY' })
    }

    return list
  }
  return [
    { label: $t('report.transactionType'), value: 'TRANSACTION_TYPE' },
    { label: $t('common.currency'), value: 'CURRENCY' },
    { label: $t('report.Date'), value: 'DATETIME' },
    // { label: $t('common.amount'), value: 'AMOUNT' },
    // { label: $t('common.amount'), value: 'AMOUNT_FC' },
    { label: $t('report.treasuryAccount'), value: 'ENTITY_ACCOUNT' }
    // { label: $t('common.counterparty'), value: 'COUNTERPARTY' },
    // { label: $t('report.memo'), value: 'MEMO' }
  ]
})

const conditionTypeWidth = computed(() => {
  let width = '100%'
  if (!conditionData.value.type && props.conditionList.length === 1) {
    return '655px'
  }
  if (!conditionData.value.type) {
    return '661px'
  }
  const wide = ['DATETIME', 'AMOUNT', 'AMOUNT_FC', 'MEMO', 'AUXILIARY']
  const wider = ['TRANSACTION_TYPE', 'ENTITY_ACCOUNT', 'CURRENCY', 'COUNTERPARTY']

  if (includes(wide, conditionData.value.type)) {
    if (['AMOUNT', 'AMOUNT_FC'].includes(conditionData.value.type) && conditionData.value.operator === 'BETWEEN') {
      width = '159.25px'
    } else {
      width = '215px'
    }
  }
  if (includes(wider, conditionData.value.type)) {
    width = '326.5px'
  }
  return width
})

const accountList = computed(() => {
  const list = accountStore.accountList.map((item: any) => {
    return {
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      alt: item.platform?.name
    }
  })
  if (props.type === 'ledger') {
    list.push({
      value: 'NOT_SET',
      label: 'Not Set',
      icon: '',
      alt: ''
    })
  }
  return list
})

const currencyOptions = computed(() => {
  return currenciesData.value.map((item: any) => {
    return {
      value: item?.symbol,
      id: item?.underlyingCurrencyId,
      label: item?.showSymbol,
      icon: item?.logo,
      alt: item?.name
    }
  })
})

const counterpartyList = computed(() => {
  const list = counterpartyOptions.value.map((item: any) => {
    return {
      value: item.counterpartyId,
      label: item.name
    }
  })
  if (props.type === 'ledger') {
    list.push({
      value: 'NOT_SET',
      label: 'Not Set'
    })
  }
  return list
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const disableConditionType = computed(() => {
  return (type: string) => {
    const types = props.conditionList.map((item: any) => item.type)
    return includes(types, type)
  }
})

const onChangeCondition = () => {
  if (!conditionData.value.operator) conditionData.value.operator = 'EQ'
  emit('onConditionChange', props.index, unref(conditionData.value))
}

const onChangeTransactionType = () => {
  const { onChangeGroupSelectData } = transactionSelectRef.value
  const differenceType: any =
    difference(conditionData.value.value, transactionSelectRef.value?.groupSelectData.lastTimeData)?.[0] ??
    difference(transactionSelectRef.value?.groupSelectData.lastTimeData, conditionData.value.value)?.[0] ??
    ''
  const type = differenceType ? capitalize(differenceType.split('_')[0]) : ''
  onChangeGroupSelectData?.('lastTimeData', conditionData.value.value)
  const typeList = transactionType.find((item: any) => item.type === type)?.list
  if (typeList) {
    const typeDataLength = conditionData.value.value.filter((i: any) => capitalize(i.split('_')[0]) === type).length
    onChangeGroupSelectData?.(`isIndeterminate${type}`, !!(typeDataLength > 0 && typeDataLength < typeList.length))
    onChangeGroupSelectData?.(`checkAll${type}`, typeDataLength === typeList.length)
  } else {
    onChangeGroupSelectData?.(`isIndeterminate${type}`, false)
    onChangeGroupSelectData?.(`checkAll${type}`, false)
  }
  onChangeCondition()
}

const onDeleteCondition = () => {
  if (props.index === 0 && !props.condition.type) return
  if (props.index === 0 && props.condition.type && props.conditionList.length === 1) {
    emit('onConditionChange', props.index, conditionData.value, 'reset')
    return
  }
  emit('onConditionChange', props.index, conditionData.value, 'delete')
}

const onChangeAuxiliary = (val: string) => {
  if (val === 'NOT_SET') {
    conditionData.value.operator = 'EXCLUDES'
  } else {
    conditionData.value.operator = 'EQ'
  }
  // // eslint-disable-next-line no-unsafe-optional-chaining
  // if (val?.length > 1 && val[val?.length - 1] === 'NOT_SET' && find(val, (i: any) => i === 'NOT_SET')) {
  //   conditionData.value.value = ['NOT_SET']
  // } else if (find(val, (i: any) => i === 'NOT_SET')) {
  //   conditionData.value.value = val.filter((i: any) => i !== 'NOT_SET')
  // } else {
  //   conditionData.value.value = val
  // }
  onChangeCondition()
}

const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    try {
      currencyLoading.value = true
      const params = {
        recommend: false,
        entityId: entityId.value,
        keywords: [query]
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
    } catch (error) {
      currenciesData.value = []
    } finally {
      currencyLoading.value = false
    }
  } else {
    currenciesData.value = props.currencyList
  }
}

const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchCounterpartyLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
    } catch (error) {
      console.log(error)
    } finally {
      searchCounterpartyLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

const onAddCondition = () => {
  emit('addCondition', props.index)
}

const onChangeType = () => {
  if (['AMOUNT', 'AMOUNT_FC'].includes(conditionData.value.type)) {
    if (conditionData.value.operator === 'BETWEEN') {
      conditionData.value.value = ['', '']
    } else {
      conditionData.value.value = ['']
    }
  } else {
    conditionData.value.value = ''
  }
  addressData.value = {}
  conditionData.value = pick(conditionData.value, ['operator', 'value', 'type'])
  onChangeCondition()
}

const onChangeConditionType = (condition: any) => {
  conditionData.value = {
    type: condition,
    operator: 'IN',
    value: []
  }
  switch (condition) {
    case 'ENTITY_ACCOUNT':
      conditionData.value.value = {
        entityAccountIds: []
      }
      break
    case 'COUNTERPARTY':
      conditionData.value.value = {
        counterpartyIds: []
      }
      break
    case 'AMOUNT':
    case 'AMOUNT_FC':
      conditionData.value.value = ['']
      conditionData.value.operator = ''
      break
    case 'TRANSACTION_TYPE':
    case 'PLATFORM_TYPE':
      conditionData.value.value = []
      conditionData.value.operator = 'IN'
      break
    case 'DATETIME':
      conditionData.value.operator = 'ON'
      conditionData.value.value = ''
      break
    case 'MEMO':
      conditionData.value.operator = ''
      break
    case 'AUXILIARY':
      conditionData.value.operator = 'EQ'
      conditionData.value.value = {
        auxiliaryTypeId: props.entityAccountAuxiliaryTypeId,
        entityAccountId: '',
        counterpartyId: '',
        auxiliaryItemId: ''
      }
      break
    default:
      break
  }
  onChangeCondition()
}

watchEffect(() => {
  if (!isEmpty(props.condition)) {
    conditionData.value = props.condition
    if (props.condition.type === 'CURRENCY' && props.condition.value.length) {
      if (props.condition.value.every((i: any) => isObject(i))) {
        conditionData.value.value = props.condition.value.map((item: any) => item?.symbol)
        const list = [...cloneDeep(currenciesData.value), ...cloneDeep(props.condition?.underlyingCurrencyList)]
        currenciesData.value = uniqBy(list, 'underlyingCurrencyId')
      }
    }
    if (props.condition.type === 'ENTITY_ACCOUNT' && props.condition.value.length) {
      conditionData.value.value = {
        entityAccountIds: props.condition.value.map((item: any) => item.entityAccountId)
      }
    }
    if (props.condition.type === 'COUNTERPARTY' && props.condition.value.length) {
      if (isArray(props.condition.value) && props.condition.value?.every((i: any) => i?.type)) {
        const list = [
          ...cloneDeep(props.condition.value),
          ...cloneDeep(originCounterpartyOptions.value),
          ...cloneDeep(accountStore.counterpartList.list)
        ]
        originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
        counterpartyOptions.value = uniqBy(list, 'counterpartyId')
      }
      conditionData.value.value = {
        counterpartyIds: props.condition.value.map((item: any) => item.counterpartyId)
      }
    }
    if (['AMOUNT', 'AMOUNT_FC'].includes(props.condition.type) && props.condition.value === '') {
      conditionData.value.value = props.condition.type === 'BETWEEN' ? ['', ''] : ['']
    }
  }
})

watch(
  () => accountStore.counterpartList,
  () => {
    if (accountStore.counterpartList.total) {
      const list = [...cloneDeep(originCounterpartyOptions.value), ...cloneDeep(accountStore.counterpartList.list)]
      originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
      counterpartyOptions.value = uniqBy(list, 'counterpartyId')
    }
  },
  { immediate: true }
)

watchEffect(() => {
  if (props.currencyList.length) {
    const list = [...cloneDeep(currenciesData.value), ...cloneDeep(props.currencyList)]
    currenciesData.value = uniqBy(list, 'underlyingCurrencyId')
  }
})
</script>

<style lang="scss">
.elv-match-set-filter-conditions {
  display: flex;
  position: relative;
  margin-bottom: 8px;

  .el-select {
    width: fit-content;

    .el-select__prefix {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-right: 3px;
    }
  }

  .elv-match-set-filter-condition-type {
    .el-select__wrapper {
      width: v-bind('conditionTypeWidth') !important;
    }
  }

  .elv-match-set-filter-condition-identity-input {
    width: 365px !important;
    margin-left: 8px;

    .el-input__wrapper {
      height: 44px;
      box-sizing: border-box;

      .el-input__prefix {
        margin-right: 10px;
      }
    }
  }

  .elv-match-set-filter-condition-long-input {
    margin-left: 8px;

    .el-select__wrapper,
    .el-input {
      width: 326.5px !important;
    }
  }

  .elv-match-set-filter-condition-short-input {
    width: 215px !important;

    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 215px !important;
    }

    &.el-input {
      .el-input__wrapper {
        height: 44px;
        box-sizing: border-box;
        width: 215px !important;
        max-width: 215px !important;
      }
    }
  }

  .elv-match-set-filter-condition-shorter-input {
    width: 159.25px !important;
    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 159.25px !important;
    }

    &.el-input {
      height: 44px !important;

      .el-input__wrapper {
        height: 100%;
        box-sizing: border-box;
        width: 159.25px !important;
        max-width: 159.25px !important;
      }
    }
  }

  .elv-match-set-filter-drawer-address-select {
    box-sizing: border-box;
    height: 44px;
    width: 215px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: border, box-shadow;

    .elv-match-set-filter-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      fill: #838d95;
      transition: transform 0.3s;
    }

    .elv-match-set-filter-drawer-time-value {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .elv-match-set-filter-drawer-currency-select {
    box-sizing: border-box;
    height: 36px;
    width: 360px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;

    .elv-match-set-filter-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      bottom: 8px;
      fill: #838d95;
      transition: transform 0.3s;
    }
  }

  .elv-match-set-filter-condition-operating {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    margin-left: 8px;

    svg {
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .elv-match-set-filter-condition-operating__delete {
      margin-right: 8px;
      fill: #838d95;

      &.is-disabled {
        fill: #d0d4d9;
        cursor: not-allowed;
      }
    }

    .elv-match-set-filter-condition-operating__add {
      fill: #838d95;
    }
  }
}

.elv-match-set-filter-condition-input-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    &:not(.is-disabled) &.hover {
      background: #f9fafb;
    }

    &.is-disabled {
      color: #a8abb2;
    }

    &.is-selected:after {
      display: none;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
