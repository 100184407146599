<template>
  <div class="elv-reconciliation-match-rule-table-row__cell-conditions">
    <div
      v-for="(condition, index) in props.data?.toleranceList"
      :key="index"
      class="elv-reconciliation-match-rule-table-cell-conditions__item"
    >
      <span class="elv-reconciliation-match-rule-table-row__cell-action__label">
        Trx {{ currentTrxConditionType(condition.transactionField).value }}
      </span>
      <p class="elv-reconciliation-match-rule-table-cell-conditions__item-is">
        <span>=</span>
      </p>
      <div class="elv-reconciliation-match-rule-table-cell-conditions__item-value">
        <span>GL {{ currentGlConditionType(condition).value }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { capitalize } from 'lodash-es'
import { useComputedHook } from '@/hooks/useComputedHook'

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()

const currentTrxConditionType: any = useComputedHook((type: string) => {
  let label = ''
  switch (type) {
    case 'direction':
      label = t('common.type')
      break
    case 'CURRENCY':
      label = t('common.currency')
      break
    case 'TRANSACTION_HASH':
      label = 'Transaction ID'
      break
    case 'AMOUNT':
      label = t('common.amount')
      break
    case 'DATETIME':
      label = t('common.date')
      break
    case 'ENTITY_ACCOUNT':
      label = t('common.account')
      break
    default:
      label = type
      break
  }
  return label
})

const currentGlConditionType: any = useComputedHook((row: any) => {
  let label = ''
  switch (row.ledgerField) {
    case 'direction':
      label = t('common.type')
      break
    case 'CURRENCY':
      label = t('common.currency')
      break
    case 'ADDITIONAL_ITEM':
      label = `additional.${row.additionalItemPath}`
      break
    case 'TRANSACTION_HASH':
      label = 'Transaction ID'
      break
    case 'AUXILIARY':
      label = 'Auxiliary.Account'
      break
    case 'REFERENCE_NO':
      label = 'Reference No.'
      break
    case 'AMOUNT':
      if (row.type === 'EQUALS') {
        label = t('common.amount')
      } else {
        label = `${t('common.amount')}${
          row.lowerLimit !== 0 && row.upperLimit !== 0 ? ` (+${row.upperLimit}, ${row.lowerLimit})` : ''
        }`
      }
      break
    case 'DATETIME':
      if (row.type === 'EQUALS') {
        label = t('common.date')
      } else {
        label = `${t('common.date')}${
          row.lowerLimit !== 0 && row.upperLimit !== 0
            ? ` (+${row.upperLimit} ${capitalize(row.limitUnit)}, ${row.lowerLimit} ${capitalize(row.limitUnit)})`
            : ''
        }`
      }
      break
    default:
      label = row.ledgerField
      break
  }
  return label
})
</script>

<style lang="scss" scoped>
.elv-sources-rule-table-row__cell-conditions {
  padding: 11px 0;
  display: flex;
  flex-direction: column;

  .elv-sources-rule-table-cell-conditions__item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .elv-sources-rule-table-cell-conditions__item-type {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;
  }

  .elv-sources-rule-table-cell-conditions__item-is {
    margin: 0 6px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #636b75;

    span:nth-child(2) {
      margin-left: 4px;
    }
  }

  .elv-sources-rule-table-cell-conditions__item-value {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 18px;

    svg,
    img {
      display: block;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }

  .elv-sources-rule-table-row__cell-action__value {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-left: 4px;
  }
}
</style>
