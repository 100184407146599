<template>
  <el-select
    v-model="operator"
    popper-class="elv-match-set-filter-condition-input-popper"
    :class="[
      operator === 'BETWEEN'
        ? 'elv-match-set-filter-condition-shorter-input'
        : 'elv-match-set-filter-condition-short-input'
    ]"
    placeholder="Select"
    @change="() => emit('onChangeType')"
  >
    <el-option
      v-for="(item, i) in amountOperatorOptions"
      :key="i"
      :label="transformI18n(item.label)"
      :value="item.value"
    />
  </el-select>

  <el-input
    v-model="value[0]"
    :class="[
      operator === 'BETWEEN'
        ? 'elv-match-set-filter-condition-shorter-input'
        : 'elv-match-set-filter-condition-short-input'
    ]"
    @change="() => emit('onChangeCondition')"
  />
  <el-input
    v-if="operator === 'BETWEEN'"
    v-model="value[1]"
    class="elv-match-set-filter-condition-shorter-input"
    @change="() => emit('onChangeCondition')"
    ><template #prefix><span>and</span></template></el-input
  >
</template>

<script setup lang="ts">
import { transformI18n } from '@/i18n/index'
import { amountOperatorOptions } from '@/config/index'

const value = defineModel<any>('value', { required: true })
const operator = defineModel<any>('operator', { required: true })

const emit = defineEmits(['onChangeType', 'onChangeCondition'])
</script>

<style lang="scss" scoped></style>
