<template>
  <el-select
    v-model="auxiliaryTypeId"
    popper-class="elv-match-set-filter-condition-input-popper"
    class="elv-match-set-filter-condition-short-input"
    placeholder=" "
    @change="() => ((value.entityAccountId = ''), (value.counterpartyId = ''), (value.auxiliaryItemId = ''))"
  >
    <el-option
      v-for="item in auxiliaryTypes"
      :key="item.auxiliaryTypeId"
      :label="item.name"
      :value="item.auxiliaryTypeId"
    />
  </el-select>

  <el-select
    v-if="!auxiliaryTypeId"
    class="elv-match-set-filter-condition-short-input"
    placeholder=" "
    :disabled="true"
  />

  <SingleChoiceSelect
    v-else-if="auxiliaryType?.type === 'ENTITY_ACCOUNT'"
    v-model="value.entityAccountId"
    placeholder="Select account"
    popper-class="elv-match-set-filter-condition-auxiliary-popper"
    class="elv-match-set-filter-condition-short-input"
    width="360px"
    filterable
    :options="props.accountList"
    :popper-append-to-body="false"
    @change="onChangeAuxiliary"
  />

  <SingleChoiceSelect
    v-else-if="auxiliaryType?.type === 'COUNTERPARTY'"
    v-model="value.counterpartyId"
    :placeholder="t('placeholder.enterNameForSearch')"
    class="elv-match-set-filter-condition-short-input"
    popper-class="elv-match-set-filter-condition-auxiliary-popper"
    width="360px"
    filterable
    remote
    remote-show-suffix
    reserve-keyword
    :loading="searchCounterpartyLoading"
    :remote-method="(query: string) => emit('remoteCounterpartyMethod', query)"
    :options="counterpartyList"
    :popper-append-to-body="false"
    @change="onChangeAuxiliary"
  />

  <SingleChoiceSelect
    v-else-if="auxiliaryType?.type === 'ITEM'"
    v-model="value.auxiliaryItemId"
    placeholder="Select ..."
    class="elv-match-set-filter-condition-short-input"
    popper-class="elv-match-set-filter-condition-auxiliary-popper"
    width="360px"
    filterable
    :options="auxiliaryItems"
    :popper-append-to-body="false"
    @change="onChangeAuxiliary"
  />
</template>

<script setup lang="ts">
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const props = defineProps({
  accountList: {
    type: Array<any>,
    required: true
  },
  counterpartyList: {
    type: Array<any>,
    required: true
  },
  auxiliaryTypes: {
    type: Array<any>,
    required: true
  },
  searchCounterpartyLoading: {
    type: Boolean,
    required: true
  }
})

const value = defineModel<any>('value', { required: true })
const auxiliaryTypeId = defineModel<string>('auxiliaryTypeId', { required: true })

const { t } = useI18n()
const emit = defineEmits(['onChangeType', 'onChangeCondition', 'onChangeAuxiliary', 'remoteCounterpartyMethod'])

const auxiliaryType = computed(() => {
  if (!auxiliaryTypeId.value) return {}
  return props.auxiliaryTypes.find((item: any) => item.auxiliaryTypeId === auxiliaryTypeId.value)
})

const onChangeAuxiliary = (val: string) => {
  emit('onChangeAuxiliary', val)
}

const auxiliaryItems = computed(() => {
  if (!auxiliaryType.value) return []
  const list = auxiliaryType.value.auxiliaryItems.map((item: any) => ({
    label: item.value,
    value: item.auxiliaryItemId
  }))

  list.push({
    value: 'NOT_SET',
    label: 'Not Set'
  })
  return list
})
</script>

<style lang="scss">
.elv-match-set-filter-condition-auxiliary-popper.el-select-dropdown {
  .el-select-dropdown__item {
    &.is-selected:after {
      display: none;
    }

    &:last-of-type {
      height: 40px;
      padding: 0 12px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-top: 1px solid #edf0f3;
    }
  }
}
</style>
